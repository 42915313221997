import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairFixingForMen = () => {

  const seoData = {
    title: 'Hair Fixing in Delhi - Radiance Hair Studio',
    description: 'Facing hair problems? We are here to serve you with the most advanced hair fixing methods in Delhi by certified experts at Radiance Hair Studio.',
    keywords: ['Hair Fixing in Delhi'],
  };

  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Fixing-for-Men's.jpg",
      content: (
        <div>
          <h2>Hair Fixing in Delhi- Radiance Hair Studio</h2>
          <p>Baldness, extreme hair fall and hair fall at some parts of your scalp – these three are the major reasons to undergo hair loss treatment. You mostly concentrate here on the types of hair treatment, but methods applied for hair fixing in Delhi is another major area, that you must look about.</p>
          <h2>Radiance Fixes Everything</h2>
          <p>Radiance Hair Studio is having the best professionals with perfect training to give you the best <a href="/non-surgical-hair-replacement-for-men.php" style={{ color: "#373737"}}><strong>Non-Surgical Hair replacement</strong></a>. Experience is quite there with us. Along with that we are perfectly equipped with all the latest techniques of this particular fashion world. </p>
          <p>There are three key methods to fix your hair externally – using adhesive, using tapes and clips. Each of these types of hair fixation needs special skills and special know-how. Professionals out there at Radiance are excellent in dealing with all types of fixing methods and all of them are applied to the clients with absolute care. The products we use in hair fixing like clips, glues, and tapes are completely safe and certified by medical science. Moreover, here you don’t need to get worried about artificial synthetic hair extensions. Because at our hair studio, extensions are made of 100% natural human hair and can also be customized frequently as per your own preference. So, when you choose us for the purpose of <strong>hair fixing in Delhi</strong>, there is absolutely nothing to be worried about. </p>
          <p>Try to get through the benefits of these fixing processes and some related queries to equip yourself with better knowledge.</p>
        </div>
      )
    }
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-fixing1.png",
      largeImg: "/hair-fixing2.png",
      content: (
        <div>
          <h2>Benefits of Hair Fixing in Delhi</h2>
          <p></p>
          <ul >
            <li>The first good thing here is the external process. Unlike the surgical treatments, <strong class="fw4">Non-Surgical Hair replacement</strong> has no strain giving episode for the clients. So, stay relaxed and wait for the new look, that you are going to develop.  </li>
            <li>The wonderful look that you will get with the natural human <a href="/hair-bonding-for-women.php" style={{ color: "#373737"}}>hair</a> of males is surely the next benefit that you can think of. Those who know you before will simply remain astonished at your new and natural look for this hair fixing. </li>
            <li>Since applied externally, these fixing methods have no side-effects too. The applied natural hair or synthetic hair style will wear out from time to time. So, you need to replace them with time.</li>
            <li>You can change your hair-style anytime while using these fixing methods. Since applied externally, they can be easily removed and restyled for you.</li>
          </ul>
        </div>
      )
    }
  ];
  
  const sectionS4 = [
    {
      title: "FAQs for Hair Fixing",
      description: "You still might have some confusion about hair fixing and some quires are possibly roaming around your mind. Not an issue. Here we tried to answer some questions often asked by customers. ",
      description2: "",
      faq: [
        {
          title: "Will the treatment give strain?",
          description: "The hair extension treatments, where you undergo surgery are painful, but that is not true in this case. The treatment done here at Radiance is entirely an external one and so is absolutely pain-free. You will just have to wait a while for the adhesive glue to dry out – nothing more than that."
        },
        {
          title: "Is there any particular life-span of the hair fixing?",
          description: "Life-span depends on the Non-Surgical Hair replacement that will be applied. In case of adhesive, the life-span is usually 3-4 weeks, while in case of tapes it goes to 6 weeks and even 8 weeks. In the case of clips, it depends on the way you handle the clips."
        },
        {
          title: "Which method of fixing is safest?",
          description: "There is nothing called side-effects here. Adhesive or tapes that are used on the skull are perfectly medicated – so they are also not going to do any harm to you."
        },
        {
          title: "Will the look be a natural one?",
          description: "At Radiance, we use natural hair only in hair fixing in Delhi. So, the look that you will get in terms of hair is completely natural. If you consider the pasting method, even a closer look at your scalp will not reveal the extension, you applied."
        }
      ]
    }
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} />
        <Layout>
          <TitleBar2 title="Hair Fixing for Men's" banner="/hair-fixing-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Fixing in Delhi- Radiance Hair Studio" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairFixingForMen;
